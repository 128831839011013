/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v28-latin-300.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v28-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/open-sans-v28-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-500.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v28-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v28-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/open-sans-v28-latin-800.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-800.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/open-sans-v28-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-300italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-300italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/open-sans-v28-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-500italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/open-sans-v28-latin-500italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-500italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-500italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-500italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-500italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-500italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/open-sans-v28-latin-600italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-600italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-600italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-600italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-600italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-600italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/open-sans-v28-latin-700italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-700italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-700italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/open-sans-v28-latin-800italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/open-sans-v28-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/open-sans-v28-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/open-sans-v28-latin-800italic.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/open-sans-v28-latin-800italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/open-sans-v28-latin-800italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

:root {
  --amplify-fonts-default-variable: "Open Sans" !important;
  --amplify-fonts-default-static: "Open Sans" !important;

  --amplify-line-heights-medium: 1.2 !important;
}

body {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  line-height: 1.43 !important;
  color: #445262 !important;
  background-color: #F7F7F7 !important;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.LogInPaper {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.App {
  overflow: hidden;
  height: 100vh;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  margin-left: 15px;
  text-align: left;
  float: left;
}

.right {
  margin-right: 15px;
  text-align: right;
  float: right;
}

.MainDrawer {
  width: 200px;
  height: 100vh;
}

.MainAppBar {
  background-image: linear-gradient(to right, #08286a, #010933)
}

.MainAppBar span, .MainDrawer span, .MainAppBar button, .MainDrawer button {
  color: #fff;
}

.MainAppBar .MuiToolbar-root {
  min-height: 45px;
  height: 45px;
  padding-right: 0px;
  padding-left: 0px;
  z-index: 1;
}

.MainAppBar .MuiContainer-root {
  padding-right: 5px;
  padding-left: 5px;
  min-height: 45px;
  height: 45px;
}

.MainAppBar .MuiListItem-root {
  width: auto;
}

.MainAppBar .MuiList-padding {
  padding: 0px;
}

.MainAppBar .MuiListItem-gutters {
  padding-left: 0px;
  padding-right: 0px;
}

.MainAppBar .MuiButton-startIcon {
  margin-right: 0px;
}

.MainAppBar .MuiButton-endIcon {
  margin-left: 2px;
}

.navDisplayFlex {
  display: flex;
  justify-content: space-between;
}

.secondaryNavDisplayFlex {
  display: flex;
}

.secondaryNavDisplayFlex a,
.secondaryNavDisplayFlex span {
  font-size: 0.85em;
}

.customerAutoComplete input {
  color: white;
  text-transform: uppercase;
  font-size: 0.85em;
}

.AmountColumn, .AmountColumn td, .AmountColumn th {
  text-align: right !important;
}

.marginButton {
  margin-right: 10px !important;
}

.logo {
  height: 40px;
  padding-top: 4px;
  padding-right: 20px;
  padding-left: 20px;
}

.main {
  margin: 20px;
  height: calc(100vh - 130px);
}

.Landing {
  margin-bottom: 1rem;
  min-height: 350px;
  background-image: linear-gradient(to right, #08286a, #010933);
  clip-path: ellipse(100% 350px at top center);
}

.Landing .bigEllipse {
  position: absolute;
  right: 0;
  top: 53px;
  z-index: -1;
}

.Landing .smallEllipse {
  position: absolute;
  right: 0;
  top: 200px;
  z-index: -1;
}

.Landing .ModuleButton:hover {
  transform: scale(1.05);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.BackDrop {
  z-index: 9999999 !important;
}

.tableauIframe {
  display: block;
  position: relative;
  background-color: white;
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  border: none;
}

.NavDivider {
  padding-top: 14px;
  padding-bottom: 14px;
}


.DividerContainer {
  padding: 15px 0px 5px 0px;
}

.DividerBorder {
  border-bottom: 0.125rem solid rgba(68, 82, 98, .2);
  flex: 1;
}

.DividerContent {
  color: #445262;
  display: flex;
  align-items: center;
}

.MuiDialogActions-root {
  background-color: whitesmoke;
}

.MuiDialogTitle-root {
  border-bottom: 0.25rem solid rgba(68, 82, 98, .2);
}

.Integrations .Mui-disabled {
  opacity: 0.2;
}

.IntegrationCustomerSelector {
  min-width: 150px !important;
  margin-left: 20px !important;
}

.CronEditor {
  display: flex;
  align-items: baseline;
}

.CronEditor .MuiFormControl-root:not(.MuiTextField-root) {
  min-width: 150px;
  margin-right: 15px;
}

.ql-container{
  min-height: 300px;
}

.Flag {
  width: 1em !important;
  height: 1em !important;
  padding-left: 10px;
}

.CountryFlagListIcon {
  margin-right: 10px;
}

.NotFoundImage {
  margin: auto;
  width: 30%
}

.NotFoundImage svg {
  width: 50% !important;
  height: 100% !important;
}

.IcGuidSearch {
  width: 250px !important;
}

.MuiTableHead-root th,
.MuiTableRow-root td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiTable-root td {
  font-size: 0.85rem !important;
}

.MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
  background: #f7f7f7;
}

.MuiTableHead-root {
  border-bottom: 1px solid rgb(175, 175, 175);
  text-transform: uppercase;
}

.MuiTableHead-root th {
  text-transform: uppercase;
}

.MuiTableRow-footer {
  justify-items: end;
  border-top: 1px solid rgb(175, 175, 175);
}

.MuiTableCell-sizeMedium:not(.MuiTablePagination-root, .MuiToolbar-root, .MuiInputBase-input, .MuiBox-root, .MuiButtonBase-root, .MuiSvgIcon-root, .MuiTableCell-head){
  background-image: url(../images/zero-state-drag-drop.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: white;
  padding-top: 190px !important;
  padding-bottom: 30px !important;
  vertical-align: top;
}

.modal-footer .MuiButton-root {
  margin-left: 10px;
}

.AdminTable {
  margin-top: 10px;
}

.statusSearchSelect .MuiSelect-root {
  display: flex;
}

.statusSearchSelect .MuiSvgIcon-root {
  font-size: 1.2em;
  margin-bottom: -5px;
}

.ExceptionSearchTable .MuiTableCell-head svg {
  height: 0.85em;
  margin-left: 2px;
}

.ExceptionSearchTable .PrivateSwitchBase-root-79 {
  padding: 1px 4px 1px 4px;
}

.ValidationSearchTable .MuiTableHead-root, .ExceptionSearchTable .MuiTableHead-root {
  height: 40px !important;
}

.AdminTable .MuiTableCell-sizeSmall:not(.MuiTableCell-alignRight), .ValidationSearchTable .MuiTableCell-sizeSmall:not(.MuiTableCell-alignRight), .ExceptionSearchTable .MuiTableCell-sizeSmall:not(.MuiTableCell-alignRight){
  padding: 1px 4px 1px 4px !important;
}

.MuiTableCell-alignRight{
  padding-right: 20px !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
  width: fit-content !important;
}

.MuiTableRow-footer .MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableBody-root .MuiTableRow-root:hover {
  background-color: #d3e6ed !important;
}

.MuiTableRow-root:not(.MuiTableRow-footer) {
  height: 0px !important;
}

.MuiTableBody-root tr:last-child {
  height: 0px !important;
}

.MuiTableCell-root button:first-child {
  margin-left: auto;
}

.Integrations .MuiTableCell-root span:first-child {
  margin-left: auto;
}

.MuiTableCell-paddingCheckbox {
  text-align: right !important;
  padding-right: 16px !important;
}

.MuiFormLabel-root {
  font-size: 0.75em !important;
}

.LogTable {
  height: calc(100vh - 350px);
}

.LogTable .MuiPaper-root,
.LogTable div:nth-child(2) {
  height: 100%;
}

.LogTable .MuiToolbar-root {
  display: none;
}

.PaddedPaper {
  padding: 10px;
  height: calc(100vh - 140px);
}

.SideNavDrawer .MuiDrawer-paper {
  overflow-x: hidden;
  margin-top: 105px;
  margin-left: 20px;
  height: calc(100% - 130px);
  background: linear-gradient(45deg, #010933, #08286a);
}

.SideNavDrawer svg {
  color: white;
}

.SideNavDrawer .MuiListItemIcon-root {
  min-width: 40px;
}

.SideNavDrawer span {
  font-size: 0.85rem;
}

.SideNavDrawer .ivsAdminList .MuiListItemText-root {
  margin-left: 50px;
}

.SideNavDrawer .ivsAdminList .MuiListItem-root {
  padding-top: 2px;
  padding-bottom: 2px;
}

.SideNavDrawer .ivsAdminList .MuiListItemText-root span {
  font-size: 0.8rem !important;
}

.SideBar {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.ValidationServicePaper {
  display: flex;
  padding-bottom: 0px !important;
  height: 100%
}

.ValidationSearchForm .MuiPaper-root {
  background-color: #F7F7F7 !important;
  margin: 10px !important;
}

.ValidationSearchForm .MuiAccordion-root .Mui-focusVisible {
  background-color: #F7F7F7 !important;
}

.ValidationSearchForm .MuiAccordionSummary-content, .ValidationSearchForm .MuiAccordionDetails-root {
  display: block;
}

.ValidationSearchForm .MuiFormControl-root:not(.companyAutoComplete), .ValidationSearchForm .MuiFormLabel-root:not(.companyAutoComplete), .ValidationSearchForm .MuiButton-root:not(.companyAutoComplete) {
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 100px;
  max-width: 160px;
}

.ManageIvsDocument .MuiFormControl-root, .ManageIvsDocument .MuiFormLabel-root, .ManageIvsDocument .MuiButton-root {
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 100px;
}

.ValidationDocumentForm .MuiButton-root, .ValidationSearchForm .MuiButton-root, .ManageIvsDocument .MuiButton-root {
  vertical-align: bottom;
}

.ValidationDocumentForm .MuiInput-underline {
  padding-top: 2px !important;
}

.ValidationDocumentForm .MuiFilledInput-adornedEnd {
  padding-right: 0px !important;
}

.ValidationDocumentForm .MuiInputBase-adornedEnd {
  padding-right: 0px !important;
}

.ValidationDocumentForm .MuiFormControl-root, .ManageIvsDocument .MuiFormControl-root {
  margin-right: 10px;
}

.ManageIvsDocument input, .ValidationDocumentForm input, .ValidationDocumentForm th, .ValidationDocumentForm td {
  font-size: 0.85rem !important;
}

.ValidationDocumentForm .MuiInput-formControl {
  padding-right: 24px;
}

.DocumentButtonBar {
  min-height: 40px;
}

.SaveButton {
  margin-right: 1px !important;
  height: 36px;
}

.DropDownButton {
  height: 36px;
  min-width: 30px !important;
  max-width: 30px !important;
}

.DocumentButtonBar .MuiAlert-root {
  padding: 1px 16px !important;
  width: calc(100% - 150px) !important;
}

.SearchCompanyField {
  margin-top: 0px !important;
}

.SearchIncludeDeleted {
  width: 100px !important;
  margin-bottom: 0px !important;
  padding-top: 7px !important;
}

.SearchIncludeDeleted span {
  font-size: small;
}

.ValidationSearchTable {
  margin-top: -10px;
}

.ValidationSearchTable .MuiToolbar-gutters:not(.MuiTablePagination-toolbar) .ExceptionSearchTable .MuiToolbar-gutters:not(.MuiTablePagination-toolbar) {
  max-height: 48px !important;
  min-height: 48px !important;
}

.ExceptionSearchTable .MuiPaper-root, .ValidationSearchTable .MuiPaper-root {
  box-shadow: none !important;
}

.ExceptionSearchTable .MuiToolbar-regular {
  max-height: 48px !important;
  min-height: 48px !important;
}

.ExceptionSearchTable button {
  margin-left: 10px;
}

.DataManagement .MuiBox-root {
  padding: 0px !important;
}

.HeightContainer {
  height: 100%;
  width: 100%;
  position: absolute !important;
}

.ScrollColumn {
  max-height: 70vh;
}

.AdminScrollColumn {
  height: -webkit-fill-available
}

.LandingModal .MuiDialog-paper {
  width: 60% !important;
  max-width: 60% !important;
}

.FieldActionModal .MuiDialog-paper {
  width: 50% !important;
  max-width: 50% !important;
}

.CompanyDataModal .MuiDialog-paper {
  width: 82% !important;
  max-width: 82% !important;
}

.CompanySelectModal .MuiDialog-paper {
  width: 50% !important;
  max-width: 50% !important;
}

.CompanyDataModal #synonyms {
  margin-top: 10px;
  margin-bottom: 20px;
}

.CompanyDataModal #gln {
  margin-top: 10px;
  margin-bottom: 20px;
}

.CompanySelectTable .MuiTableCell-sizeSmall {
  padding: 6px 0px !important;
}

.ValidationServiceBlock {
  display: block;
  width: 100%;
  min-width: 320px;
  overflow: hidden;
  position: relative;
}

.HistoryModal .MuiDialog-paper {
  width: 1000px !important;
  max-width: 1000px !important;
}

.history .MuiCardContent-root {
  padding: 10px !important;
}

.ChipColumn {
  padding-left: 0px !important;
  display: flex;
  align-items: center;
}

.ChipColumn .MuiButtonBase-root {
  min-width: 60px;
  margin-bottom: 10px;
}

.ReturnedChip {
  background-color: #8770ff !important;
  color: white !important;
}

.splitContainer {
  display: flex;
  height: 100%
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.split, .gutter.gutter-horizontal {
  float: left;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  cursor: row-resize;
}

.DomainSetting {
  padding: 20px 0 20px 20px;
}

.DocumentDetails .TabPanel {
  margin-left: 10px;
  margin-right: 10px;
}

.DocumentDetails .MuiAppBar-root {
  background-color: white !important;
}

.DocumentDetails .MuiBox-root .MuiToolbar-root {
  display: none;
}

.DocumentDetails .MuiBox-root .MuiPaper-root {
  box-shadow: none !important;
}

.DocumentDetails .MuiAutocomplete-root {
  display: inline-flex;
}

.DocumentDetails .MuiAutocomplete-root .MuiFilledInput-root.Mui-disabled {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.DocumentDetails .MuiAutocomplete-root .MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.54) !important;
}

.DocumentDetails .MuiAutocomplete-root .MuiFilledInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.DocumentDetails .MuiInput-underline {
  padding-right: 20px;
}

.DocumentImageContainer {
  height: calc(100vh - 250px);
  position: relative;
}

.pdfobject-container {
  position: absolute !important;
}

input[readonly] {
  cursor: default;
}

.DocumentTemplateAutoComplete {
  width: 200px;
  display: inline-flex;
  margin-left: 20px;
}

.XmlViewer .CodeMirror {
  height: 100%;
}

.ManageIvsDocumentViewer .cm-editor {
  height: calc(100vh - 450px);
}

.CustomerParams,
.AdminCodeViewer .cm-editor {
  height: calc(100vh - 340px);
}

.DoubleWidthField {
  width: 380px !important;
}

.DoubleWidthField .MuiInput-underline .MuiInputAdornment-positionEnd {
  margin-top: -10px;
}

.ValidationSearchForm .MuiAutocomplete-root {
  display: inline-flex;
  margin-top: -8px;
  min-width: 150px;
}

.FileIcon {
  min-width: 1em !important;
  width: 1.8em !important;
  margin-right: 10px;
}

.emlPopOut {
  padding: 20px;
  direction: ltr;
}

.emlPopOut td {
  min-width: 120px;
}

.emlPopOutContainer .scrollbar-container {
  direction: rtl;
}

.CustomLabel {
  color: #919191;
  font-size: 0.85em;
}

.MatrixValidatorPaper {
  background-color: #F7F7F7 !important;
  padding: 30px;
  margin: 30px;
}

.DropZone {
  background-color: #F7F7F7;
  height: calc(100vh - 450px);
  margin-top: 20px;
  border: none;
}

.DropZone:before {
  content: " ";
  display: block;
  width: 100%;
  height: 20px;
  background-image: url('../images/borderTop.svg');
  position: absolute;
  top: 0px;
  left: 0px;
}

.DropZone:after {
  content: " ";
  display: block;
  width: 100%;
  height: 20px;
  fill: pink;
  background-image: url('../images/borderBottom.svg');
  position: absolute;
  bottom: -10px;
  left: 0px;
}

.GreyPaper {
  background-color: #F7F7F7 !important;
  margin: 10px !important;
  display: flex;
}

.DoughnutGrid .MuiGrid-root {
  height: 100%;
}

.ChangeLog h1, .ChangeLog p {
  display: none
}

.ChangeLog h2, .ChangeLog h3 {
  font-size: medium;
}

.ChangeLog a {
  color: black;
  pointer-events: none;
  cursor: default;
}

.MatrixValidatorWarning {
  border: 1px solid orange;
}

.MatrixValidatorError {
  border: 1px solid red;
}

.MatrixValidatorSuccess {
  border: 1px solid #65ba68;
}

.MatrixValidatorSuccess svg {
  color: #65ba68
}

.MatrixList {
  width: 100%;
  max-height: 240px;
}

.MatrixResetButton {
  left: 30px;
}

.MatrixTestButton {
  margin-right: 30px !important;
  float: right;
}

.MatrixTestModal .MuiDialog-paper {
  width: 98% !important;
  max-width: 98% !important;
  min-width: 98% !important;
  max-height: 90vh;
}

.MatrixTestModal .MatrixValidatorPaper {
  width: 100%;
  padding: 10px !important;
  margin: 10px !important;
  max-height: 100%;
}

.MatrixTestModal .col {
  max-height: 75vh;
}

.MatrixTestForm {
  max-height: 70vh;
}

.MatrixTestForm .MuiFormControl-root, .MatrixTestForm .MuiFormLabel-root {
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 225px;
}

.MatrixTestForm .MuiFilledInput-inputMarginDense {
  padding-top: 20px !important;
}

.MatrixTestCol {
  max-width: 300px !important;
}

.MatrixTestFlow {
  width: calc(100% - 300px);
}

.MatrixTestBox {
  min-width: 250px;
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.MatrixTestBox:hover {
  border: 1px solid rgba(0, 0, 0, 1);
  cursor: pointer;
}

.MatrixTestUserWarning {
  height: min-content;
  margin-top: 5px;
  min-width: 250px;
  font-size: 0.8em !important;
}

.MatrixTestUserWarning .MuiAlert-message {
  padding: 0px !important;
  height: min-content;
}

.MatrixTestBlock {
  display: flex;
}

.MatrixTestConnector {
  color: #fff4e5;
  margin-left: -13px;
  margin-right: -15px;
  width: 1.5em !important;
  height: 1.5em !important;
  margin-top: -2px;
}

.MatrixTestCenter {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.MatrixTestError {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.MatrixTestArrow {
  padding-left: 25%;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.WarningIcon {
  color: orange;
}

.nav-link.active {
  border-bottom: 1px solid black;
}

.AdminPages {
  position: absolute;
  width: -webkit-fill-available;
  top: 160px;
  bottom: 20px;
  padding-right: 40px;
}

.AdminTabPanels .MuiBox-root {
  padding: 10px 0 0 0 !important;
}

.AdminColumn {
  margin-left: 20px;
  margin-right: 20px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.MuiTimelineItem-missingOppositeContent:before {
  content: none
}

.MuiCardContent-root {
  padding-bottom: 16px !important;
}

.cm-trailingspace {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUXCToH00Y1UgAAACFJREFUCNdjPMDBUc/AwNDAAAFMTAwMDA0OP34wQgX/AQBYgwYEx4f9lQAAAABJRU5ErkJggg==);
  background-position: bottom left;
  background-repeat: repeat-x;
}

.AdminTree {
  margin-right: 20px !important;
}

.AdminTree .MuiTreeItem-label {
  margin-left: 15px;
}

.AdminTree .MuiTreeItem-label label {
  margin-bottom: 0px !important;
}

.Customer .MuiTextField-root {
  margin-right: 10px;
}

.CriteriaColumn {
  width: 230px;
}

.ProcessColumn {
  width: 900px;
}

.smooth-dnd-container {
  padding-bottom: 50px;
}

.AddEditEmailIntegration .DividerContainer {
  margin-top: 10px;
}

.AddEditEmailIntegration .MuiGrid-item {
  width: 50%
}

.DividerExtraMargin {
  margin-top: 35px;
}

.EmailSchedule {
  display: flex;
  align-items: baseline;
}

.EmailSchedule div {
  margin-left: 5px;
  margin-right: 5px;
}

.smooth-dnd-draggable-wrapper:hover {
  background-color: lightgray;
  cursor: grab;
}